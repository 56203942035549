<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md5 v-if="caseDetails.length > 0">
        <v-card
          v-for="mergeCase in caseDetails"
          :key="mergeCase._id"
          elevation="2"
          class="my-4"
        >
          <v-layout wrap>
            <v-flex xs12>
              <v-icon color="#E34F00"> mdi-call-merge </v-icon>
              <span style="font-size: 14px; font-family: sofiaProSemiBold"
                >Merge Cases</span
              >
            </v-flex>
            <v-flex xs12 text-center>
              <v-layout wrap justify-center>
                <span
                  style="
                    color: #ff6907;
                    font-family: sofiaProRegular;
                    font-size: 12px;
                  "
                  >{{ mergeCase.mergeReportIds.length }} Cases</span
                >
                <span
                  class="pl-1"
                  style="
                    color: #626262;
                    font-family: sofiaProRegular;
                    font-size: 12px;
                  "
                >
                  reported from same location</span
                >
              </v-layout>

              <v-layout justify-center pt-1>
                <span
                  style="
                    color: #000;
                    font-family: sofiaProMedium;
                    font-size: 12px;
                  "
                  >{{ mergeCase.locationname }}</span
                >
              </v-layout>
              <v-layout justify-center pt-1>
                <span
                  style="
                    color: #b4b4b4;
                    font-family: sofiaProRegular;
                    font-size: 12px;
                  "
                >
                  Select and Merge same cases
                </span>
              </v-layout>
            </v-flex>
            <v-layout py-4>
              <v-divider></v-divider>
            </v-layout>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="(item, i) in mergeCase.mergeReportIds"
                      :key="i"
                      color="transparent"
                      small
                      fill-dot
                    >
                      <template v-slot:icon>
                        <v-checkbox
                          v-model="item.checkboxValue"
                          :color="appColor"
                          :id="item._id"
                          light
                          :ripple="false"
                        >
                        </v-checkbox>
                      </template>
                      <v-layout wrap>
                        <v-flex
                          xs3
                          sm2
                          md3
                          lg2
                          xl2
                          :class="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                              ? 'text-center'
                              : 'text-left'
                          "
                          align-self-center
                        >
                          <v-avatar
                            size="50px"
                            v-if="
                              item &&
                              item.reportImages &&
                              item.reportImages.length > 0
                            "
                          >
                            <img
                              alt="Avatar"
                              :src="mediaURL + item.reportImages[0]"
                            />
                          </v-avatar>
                          <v-avatar v-else size="50px">
                            <img
                              alt="Avatar"
                              src="@/assets/images/noimage.jpg"
                            />
                          </v-avatar>
                        </v-flex>
                        <v-flex xs8 sm7 md9 lg9 xl9>
                          <v-layout wrap>
                            <!-- <v-flex md6>
                              <v-btn
                                x-small
                                depressed
                                class="py-2"
                                :outlined="
                                  item.status == 'Pending' ? true : false
                                "
                                :ripple="false"
                                :color="
                                  item.status == 'Pending'
                                    ? '#deb710'
                                    : '#F5F5F5'
                                "
                                style="letter-spacing: 0px"
                                ><span
                                  style="
                                    font-family: sofiaProRegular;
                                    color: #000;
                                    font-size: 10px;
                                  "
                                  >RESPONSE NEEDED
                                </span></v-btn
                              >
                            </v-flex> -->
                            <v-flex md6>
                              <v-btn
                                x-small
                                depressed
                                class="py-2"
                                :outlined="
                                  item.status == 'Pending' ? true : false
                                "
                                :ripple="false"
                                :color="
                                  item.status == 'Pending'
                                    ? '#deb710'
                                    : '#F5F5F5'
                                "
                                style="letter-spacing: 0px"
                                ><span
                                  style="
                                    font-family: sofiaProRegular;
                                    color: #000;
                                    font-size: 10px;
                                  "
                                  >{{ item.scenarioId.name }}
                                </span></v-btn
                              >
                            </v-flex>
                            <v-flex md6>
                              <span
                                class="text-uppercase"
                                style="
                                  font-family: sofiaProRegular;
                                  color: #deb710;
                                  font-size: 10px;
                                "
                              >
                                {{ item.status }}</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex xs8 sm7 md8 lg8 xl8>
                              <span class="itemHeading">
                                {{ item.animalId.name }}
                              </span>
                            </v-flex>
                            <v-flex xs4 sm4 md4 lg4 xl4 text-right>
                              <span
                                style="
                                  font-family: sofiaProRegular;
                                  color: #000;
                                  font-size: 10px;
                                "
                              >
                                {{ timeSince(item.create_date) }}
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProRegular;
                                  color: #626262;
                                  font-size: 12px;
                                "
                              >
                                Threat Level:
                                <span v-if="item.threatlevel">
                                  {{ formatText(item.threatlevel) }}</span
                                >
                                <span v-else>Null</span>
                              </span>
                              <br />
                              <span
                                style="
                                  font-family: sofiaProRegular;
                                  color: #626262;
                                  font-size: 12px;
                                "
                              >
                                Location Name:
                                <span v-if="item.locationname">{{
                                  formatText(item.locationname)
                                }}</span>
                                <span v-else>Null</span>
                              </span>
                              <br />
                              <span
                                style="
                                  font-family: sofiaProRegular;
                                  color: #626262;
                                  font-size: 12px;
                                "
                              >
                                Reported Date & Time:
                                <span v-if="item.reportTime">{{
                                  formatReportTime(item.reportTime)
                                }}</span>
                                <span v-else>Null</span>
                              </span>
                              <br />
                              <span
                                style="
                                  font-family: sofiaProRegular;
                                  color: #626262;
                                  font-size: 12px;
                                "
                                v-if="item.addedBy.role"
                              >
                                Sighted by:
                                <span v-if="item.addedBy.role === 'User'">
                                  <span>{{ item.addedBy.userId.name }}</span>
                                </span>
                                <span v-if="item.addedBy.role === 'MASTER'">
                                  <span>{{
                                    item.addedBy.admin.masterName
                                  }}</span>
                                </span>
                                <span
                                  v-if="item.addedBy.role === 'EmergencyUser'"
                                >
                                  <span>{{ item.addedBy.userId.name }}</span>
                                </span>
                                <span v-if="item.addedBy.role === 'RANGE'">
                                  <span
                                    v-if="item.addedBy.range.accessCredentials"
                                    >{{
                                      item.addedBy.range.accessCredentials
                                        .username
                                    }}</span
                                  >
                                </span>
                                <span v-if="item.addedBy.role === 'DIVISION'">
                                  <span
                                    v-if="
                                      item.addedBy.division.accessCredentials
                                    "
                                    >{{
                                      item.addedBy.division.accessCredentials
                                        .username
                                    }}</span
                                  >
                                </span>
                                <span v-if="item.addedBy.role === 'RRT'">
                                  <span
                                    v-if="
                                      item.addedBy.userId &&
                                      item.addedBy.userId.name
                                    "
                                    >{{ item.addedBy.userId.name }}</span
                                  >
                                </span>
                              </span>
                              <!-- <span
                                v-if="item.userId.fullName"
                                style="
                                  font-family: sofiaProRegular;
                                  color: #626262;
                                  font-size: 12px;
                                "
                              >
                                Sighted by: {{ item.userId.fullName }}
                              </span> -->
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 :key="i" pt-4>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-timeline-item>
                  </v-timeline>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center py-4>
                <v-flex xs6 text-center>
                  <v-btn
                    block
                    color="#E34F00"
                    dark
                    :ripple="false"
                    depressed
                    type="submit"
                    @click="mergeReport(mergeCase.mergeReportIds)"
                  >
                    <span
                      style="font-size: 16px; font-family: sofiaProSemiBold"
                    >
                      MERGE
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <br />
      </v-flex>
      <v-flex xs12 md12 align-self-center text-center v-else>
        <span style="fonr-family: sofiaProBold; font-size: 18px"
          >No Data Found</span
        >
      </v-flex>
      <v-flex xs12 md7>
        <div class="posSticky" id="map"></div>
      </v-flex>
    </v-layout>
  </div>
</template>
 <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
var marker;
import axios from "axios";
// import io from "socket.io-client";
export default {
  data() {
    return {
      map: "",
      caseDetails: [],
      dataLength: null,
      erverError: false,
      checkedItems: [],
      appLoading: false,
      msg: null,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      reportedlocations:[],
      markers: [],
      infowindows: [],
    };
  },
  mounted() {
    // this.mapData = this.storage;
    this.getData();
  },
  methods: {
    formatText(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    formatReportTime(dateString) {
      const date = new Date(dateString);
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      });
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      });
      return `${time} ${formattedDate}`;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/report/merge/suggestion",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          reportId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.caseDetails = response.data.data;
            this.reportedlocations = this.caseDetails[0].mergeReportIds;
            for (var i = 0; i < this.caseDetails.length; i++) {
              for (
                var j = 0;
                j < this.caseDetails[i].mergeReportIds.length;
                j++
              ) {
                this.caseDetails[i].mergeReportIds[j].checkboxValue = false;
              }
            }
            this.dataLength = response.data.length;
            this.initMap();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    mergeReport(mergeItem) {
      var mergeIds = [];
      console.log(mergeItem, "merge");
      for (var i = 0; i < mergeItem.length; i++) {
        if (mergeItem[i].checkboxValue) {
          mergeIds.push(mergeItem[i]._id);
        }
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/report/merge",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          reportIds: mergeIds,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            // window.location.reload();
            this.$router.push({ path: "/Officers/dashboard" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
    // initMap() {
    //   var vm = this;
    //   var mapCanvas = document.getElementById("map");
    //   var mapOptions = {
    //     center: new google.maps.LatLng(
    //       this.caseDetails[0].location[1],
    //       this.caseDetails[0].location[0]
    //     ),
    //     zoom: 7,

    //     mapTypeId: google.maps.MapTypeId.TERRAIN,
    //   };
    //   vm.map = new google.maps.Map(mapCanvas, mapOptions);
    //   for (var i = 0; i < this.caseDetails.length; i++) {
    //     var lat = this.caseDetails[i].location[1];
    //     var lon = this.caseDetails[i].location[0];
    //     var latlngset = new google.maps.LatLng(lat, lon);
    //     var icon = {
    //       url: this.mediaURL + "footPrint.png", // url
    //       scaledSize: new google.maps.Size(50, 50), // scaled size
    //       origin: new google.maps.Point(0, 0), // origin
    //       anchor: new google.maps.Point(0, 0), // anchor
    //     };
    //     marker = new google.maps.Marker({
    //       map: vm.map,
    //       position: latlngset,
    //       icon: icon,
    //     });
    //   }
    // },
    initMap() {
      const mapCanvas = document.getElementById("map");
      if (!mapCanvas) return;

      const defaultLocation = { lat: 0, lng: 0 }; // Default location if caseDetails is empty

      const mapOptions = {
        center: new google.maps.LatLng(
          this.reportedlocations[0]?.location[1] || defaultLocation.lat,
          this.reportedlocations[0]?.location[0] || defaultLocation.lng
        ),
        zoom: 7,
      };
      this.map = new google.maps.Map(mapCanvas, mapOptions);

      // Clear existing markers and infowindows
      this.markers.forEach((marker) => marker.setMap(null));
      this.infowindows.forEach((infowindow) => infowindow.close());

      this.markers = [];
      this.infowindows = [];

      // Define icon URLs for different threat levels
      const icons = {
        High: require("@/assets/images/highthreat.png"),
        Medium: require("@/assets/images/mediumthreat.png"),
        Low: require("@/assets/images/lowthreat.png"),
      };

      // Add markers and info windows
      this.reportedlocations.forEach((item) => {
        const position = new google.maps.LatLng(
          item.location[1],
          item.location[0]
        );

        // Determine the icon based on threat level
        const icon = icons[item.threatlevel] || icons["Low"]; // Default to Low if threatlevel is not defined

        const marker = new google.maps.Marker({
          position,
          map: this.map,
          title: `Threat Level: ${item.threatlevel}`,
          icon: icon, // Set the icon based on threat level
        });

        const infowindow = new google.maps.InfoWindow({
          content: `
        <div style="width: 400px; height: 210px;">
          <div class="xs12 flex">
            <h2 style="font-family: sofiaProMedium;">${item.animalId.name}</h2>
          </div><br>
          <div class="xs12 flex">
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Location Name:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.locationname}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Coordinates:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.location[1]}, ${item.location[0]}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Threat Level:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.threatlevel}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Status:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.status}</span></p>
          </div>
          <div class="xs12 flex">
            <div class="layout wrap">
              <div class="flex xs5 text-left">
                <a 
                  href="https://www.google.com/maps/dir/?api=1&destination=${item.location[1]},${item.location[0]}" 
                  target="_blank" 
                  class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--medium"
                  style="background-color: #FF6907; text-decoration: none;">
                  <span class="v-btn__content py-2 px-3">
                    <i style="color:#FFF;font-size:18px;" class="fas fa-location-arrow"></i>
                    <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #FFF;"> Direction</span>
                  </span>
                </a>
              </div> 
              <div class="flex xs4 text-left">
                <span class="itemKey text-capitalize v-btn v-btn--router v-btn-outlined theme--light v-size--medium"
                  style="background-color: #FFF; border:.5px solid #EAEAEA; cursor:pointer">
                  <span class="v-btn__content py-2 px-3">
                    <i style="color:#000;font-size:18px;" class="fas fa-phone-alt"></i>
                    <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #000;"> Call</span>
                  </span>
                </span>
              </div>
            </div>
          </div> 
        </div>
      `,
        // code of share button
      // <div class="flex xs3 text-left">
      //           <span class="itemKey text-capitalize v-btn v-btn--router v-btn-outlined theme--light v-size--medium"
      //             style="background-color: #FFF; border:.5px solid #EAEAEA; cursor:pointer">
      //             <span class="v-btn__content py-2 px-3">
      //               <i style="color:#000;font-size:18px;" class="fas fa-share-alt"></i>
      //               <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #000;"> Share</span>
      //             </span>
      //           </span>
      //         </div>


        });

        marker.addListener("click", () => {
          this.infowindows.forEach((info) => info.close());
          infowindow.open(this.map, marker);
        });

        this.markers.push(marker);
        this.infowindows.push(infowindow);
      });
    },
    clickItem(item) {
      const markerIndex = this.caseDetails.indexOf(item);
      if (markerIndex !== -1) {
        google.maps.event.trigger(this.markers[markerIndex], "click");
      }
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>
<style  >
</style>
